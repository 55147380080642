import { OsTable } from '@/components';
import { PagingMixin } from '@/mixins/paging';
import { mixins } from 'vue-class-component';
import { Component, Inject } from 'vue-property-decorator';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { LogisticsOrderStatusEnum } from '@/resource/enum';
import { messageError } from '@/utils';
import { productionOrderLogisticsService } from '@/api';
import { ShippingTypeEnum } from '@/resource/enum/shiping-type';
import { LogisticsOrderResource } from '@/resource/model';

@Component({
  components: {}
})
export default class LogisticsTrackerTable extends mixins(PagingMixin) {
  /**
   * 表格引用
   */
  public $table!: OsTable;

  /**
   * 注入订单ID，便于查询数据
   */
  @Inject('orderId') public orderId!: number;

  /**
   * 查询参数
   */
  public queryForm: Partial<{
    orderId: number;
  }> = {
    orderId: this.orderId
  };

  /**
   * 表格配置项
   */
  public tableOption: OsTableOption<LogisticsOrderResource> = {
    loading: false,
    data: [],
    fit: true,
    size: 'small',
    border: true
    // defaultSort: { prop: 'createTime', order: 'descending' }
  };

  /**
   * 表格列配置
   */
  public tableColumnOptions: Array<OsTableColumn<LogisticsOrderResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      align: 'center'
    },
    {
      prop: 'code',
      label: 'orderLogisticsOrder.code',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'shippingType',
      label: 'orderLogisticsOrder.logisticsInfo',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'orderLogisticsOrder.status',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'receiveAddress',
      label: 'orderLogisticsOrder.receiveInfo',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'sendAddress',
      label: 'orderLogisticsOrder.sendInfo',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'orderLogisticsOrder.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  public mounted(): void {
    this.$table = this.$refs.tableRef as OsTable;
    this.loadData();

    const list: Array<any> = [
      {
        id: 1,
        code: 'PO202107221001-01-0001',
        shippingType: 1,
        shippingCompany: '顺丰',
        shippingCode: '123456',
        status: 1,
        receiveAddress: '收货地址信息',
        receiver: '收货人姓名',
        receiverTel: '18888888888',
        receiveTime: '2021-07-22 10:00:00',
        sendAddress: '发货地址信息',
        sender: '发货人姓名',
        senderTel: '188888888888',
        sendTime: '2021-07-22 10:00:00',
        productCount: 10,
        remark: ''
      }
    ];

    this.tableOption.data = list;

    this.totalData = 1;
  }

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getStatusI18Key(status: LogisticsOrderStatusEnum): string {
    return 'logisticsOrderStatus.' + LogisticsOrderStatusEnum[status];
  }

  /**
   * 获取出货方式国际化key
   * @param type 出货方式
   * @returns
   */
  public getShippingTypeI18nKey(type: ShippingTypeEnum): string {
    return 'shippingType.' + ShippingTypeEnum[type];
  }

  /**
   * 去物流单详情
   * @param rowData 物流单数据对象
   */
  public goLogisticsOrderDetail(rowData: LogisticsOrderResource): void {
    this.$router.push({
      path: '/logistics-order-detail',
      query: {
        orderId: rowData.id.toString()
      }
    });
  }

  /**
   * 分页切换回调
   */
  public pagingData(): void {
    this.loadData();
  }

  /**
   * 加载数据
   */
  private loadData(): void {
    this.$table.openLoading();
    productionOrderLogisticsService
      .getList(this.queryForm as LogisticsOrderResource, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.$table.closeLoading();
      });
  }
}
