import { render, staticRenderFns } from "./production-order-product-table.vue?vue&type=template&id=62e8d3f4&scoped=true&"
import script from "./production-order-product-table.ts?vue&type=script&lang=ts&"
export * from "./production-order-product-table.ts?vue&type=script&lang=ts&"
import style0 from "./production-order-product-table.scoped.scss?vue&type=style&index=0&id=62e8d3f4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62e8d3f4",
  null
  
)

export default component.exports