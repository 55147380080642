import { messageError } from '@/utils/index';
import { productionOrderService } from '@/api';
import { ProductionOrderResource } from '@/resource/model/production-management/production-order';
import { Component, Provide, Vue } from 'vue-property-decorator';
import ProductionOrderProductTable from './production-order-product-table/production-order-product-table.vue';
import { ProductionOrderStatusEnum, ProductionOrderTypeEnum } from '@/resource/enum';
import LogisticsTrackerTable from './logistics-tracker-table/logistics-tracker-table.vue';

@Component({
  components: {
    ProductionOrderProductTable,
    LogisticsTrackerTable
  }
})
export default class ProductionOrderDetail extends Vue {
  /**
   * tab 激活页
   */
  public activeName = 'productList';

  /**
   * 订单对象
   */
  public order: ProductionOrderResource | null = null;

  /**
   * 定义订单ID，便于后代组件注入
   */
  @Provide() public orderId = Number(this.$route.query.orderId);

  /**
   * vue instance created hook
   */
  public created(): void {
    this.initOrder();
  }

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getStatusI18Key(status: ProductionOrderStatusEnum): string {
    return productionOrderService.getStatusI18Key(status);
  }

  public getOrderTypeI18nKey(type: number): string {
    return 'order.' + ProductionOrderTypeEnum[type];
  }

  /**
   * 返回
   */
  public goBack(): void {
    this.$router.go(-1);
  }

  /**
   * 去新建变更单页面
   */
  public goCreateChangeLogPage(): void {
    this.$router.push({
      path: '/add-production-order-change',
      query: {
        orderId: this.orderId.toString()
      }
    });
  }

  /**
   * 初始化order数据
   */
  private initOrder(): void {
    productionOrderService
      .getById(this.orderId)
      .then((res: ProductionOrderResource) => {
        this.order = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
