import { cloneDeep } from 'lodash-es';
import { BrokenTypeEnum } from '@/resource/enum/broken-type';
import { ProductionProductStatusEnum } from '@/resource/enum/production-product-status';
import { ProcessStatusEnum } from '@/resource/enum/process-status';
import { PagingMixin } from '@/mixins/paging';

import { Component, Inject } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { OsTable } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { ProductionOrderProductResource } from '@/resource/model';
import { Paging } from '@/api/base';
import { dateFormat, messageError, translation } from '@/utils';
import {
  BrokenReasonEnum,
  ChargeUnitEnum,
  DutyAgencyTypeEnum,
  OperationTypeEnum,
  OrderProductTypeEnum,
  PrepressChangeColorCraftsEnum,
  PrepressLayoutCraftsEnum,
  ProcessActionEnum,
  ProcessEnum,
  ProjectItemTypeEnum
} from '@/resource/enum';
import { BrokenLogResource } from '@/resource/model/production-management/broken-log';
import { productionOrderProductService } from '@/api';

@Component({
  components: {}
})
export default class ProductionOrderProductTable extends mixins(PagingMixin) {
  /**
   * 表格引用
   */
  public $table!: OsTable;

  /**
   * 报损日志表格引用
   */
  public $brokenTable!: OsTable;

  /**
   * 注入订单ID，便于查询数据
   */
  @Inject('orderId') public orderId!: number;

  /**
   * 报损记录表格配置
   */
  public brokenLogTableOption: OsTableOption<BrokenLogResource> = {
    loading: false,
    data: [],
    fit: true,
    size: 'small',
    border: true,
    closeAdaptiveHeight: true
  };

  /**
   * 报损记录表格列配置
   */
  public brokenLogTableColumnOptions: Array<OsTableColumn<BrokenLogResource>> = [
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'productName',
      label: 'projectProduct.platformProduct',
      showOverflowTooltip: true,
      minWidth: '250px'
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'attritionCount',
      label: 'brokenLog.count',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'attritionArea',
      label: 'brokenLog.area',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'dutyUser',
      label: 'brokenLog.dutyUser',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'dutyReason',
      label: 'brokenLog.reason',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'attritionLinkName',
      label: 'consumed.consumeStage',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'attritionTypeName',
      label: 'consumed.consumedType',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'dutyOrganizeType',
      label: 'consumed.dutyAgencyType',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (!(row as BrokenLogResource).dutyOrganizeType) {
          return '--';
        }
        return translation(`dutyAgencyType.${DutyAgencyTypeEnum[(row as BrokenLogResource).dutyOrganizeType]}`);
      }
    },
    {
      prop: 'createUserName',
      label: 'brokenLog.createUserName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'brokenLog.createTime',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as BrokenLogResource).createTime);
      }
    }
  ];

  /**
   * 报损记录分页参数
   */
  public brokenLogTablePaging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  /**
   * 报损总记录数
   */
  public brokenLogTableTotalData = 0;

  /**
   * 产品详情tab激活页
   */
  public activeName = 'base';

  /**
   * 产品详情窗口显示标识
   */
  public detailDialogVisible = false;

  /**
   * 报损记录窗口显示标识
   */
  public brokenDetailDialogVisible = false;

  /**
   * 当前查看行数据
   */
  public viewRow: ProductionOrderProductResource | null = null;

  /**
   * 预览图列表
   */
  public previewSrcList: Array<string> = [];

  /**
   * 检索条件
   */
  public searchForm: Partial<{
    status: number;
    keywords: string;
    orderId: number;
  }> = {
    status: 0,
    keywords: '',
    orderId: undefined
  };

  /**
   * 表格配置项
   */
  public tableOption: OsTableOption<ProductionOrderProductResource> = {
    loading: false,
    data: [],
    fit: true,
    size: 'mini',
    border: true
    // defaultSort: { prop: 'createTime', order: 'descending' }
  };

  /**
   * 表格列配置
   */
  public tableColumnOptions: Array<OsTableColumn<ProductionOrderProductResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'orderProduct.itemCode',
      minWidth: '210px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'prepressConfirmFile',
      label: 'orderProduct.prepressConfirmFile',
      minWidth: '180',
      showOverflowTooltip: true
    },
    {
      prop: 'pointName',
      label: 'orderProduct.pointName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'orderProduct.platformProductName',
      minWidth: '250',
      showOverflowTooltip: true
    },
    {
      prop: 'customerProductName',
      label: 'orderProduct.customProductName',
      minWidth: '180',
      showOverflowTooltip: true
    },
    {
      prop: 'backendCrafts',
      label: 'orderProduct.backendCrafts',
      minWidth: '250',
      showOverflowTooltip: true
    },
    {
      prop: 'count',
      label: 'orderProduct.count',
      minWidth: '180',
      showOverflowTooltip: true
    },
    {
      prop: 'totalArea',
      label: 'orderProduct.totalArea',
      minWidth: '180',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleWidth',
      label: 'orderProduct.visibleWidth',
      minWidth: '180',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleHeight',
      label: 'orderProduct.visibleHeight',
      minWidth: '180',
      showOverflowTooltip: true
    },
    {
      prop: 'finishWidth',
      label: 'orderProduct.completeWidth',
      minWidth: '180',
      showOverflowTooltip: true
    },
    {
      prop: 'finishHeight',
      label: 'orderProduct.completeHeight',
      minWidth: '180',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressCrafts',
      label: 'orderProduct.prepressCrafts',
      minWidth: '180',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return this.formatterPrepressCrafts(row as ProductionOrderProductResource);
      }
    },
    {
      prop: 'prepressPicture',
      label: 'orderProduct.prepressPicture',
      minWidth: '180',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressRemark',
      label: 'orderProduct.prepressDescription',
      minWidth: '180',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'orderProduct.remark',
      minWidth: '180',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredDeliveryTime',
      label: 'orderProduct.requiredDeliveryTime',
      minWidth: '180',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredArriveTime',
      label: 'orderProduct.requiredArriveTime',
      minWidth: '180',
      showOverflowTooltip: true
    },
    {
      prop: 'processType',
      label: 'orderProduct.currentProcess',
      minWidth: '180',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'orderProduct.status',
      minWidth: '100',
      showOverflowTooltip: true
    },
    {
      prop: 'finishTime',
      label: 'orderProduct.finishTime',
      minWidth: '180',
      showOverflowTooltip: true
    }
  ];

  /**
   * 表格行操作配置
   */
  public rowOperationOption: RowOperation<ProductionOrderProductResource> = {
    fixed: 'right',
    width: '100px',
    operations: [
      {
        operationType: 'detail',
        type: 'text',
        label: 'orderProduct.brokenLog',
        icon: 'el-icon-tickets',
        permissionCode: 'production:lossRecord:listPage',
        handleClick: (row: ProductionOrderProductResource): void => {
          // 查看报损记录
          this.openBrokenLogDialog(row);
        }
      }
    ]
  };

  /**
   * 选中数据缓存对象
   */
  private selectedRows: Array<ProductionOrderProductResource> = [];

  public mounted(): void {
    this.$table = this.$refs.tableRef as OsTable;
    this.loadData();
  }

  public formatterPrepressCrafts(data: ProductionOrderProductResource): string {
    if (data.itemType === ProjectItemTypeEnum.changeColor) {
      return translation(`prepressChangeColorCrafts.${PrepressChangeColorCraftsEnum[data.prepressCrafts]}`);
    }
    return translation(`prepressLayoutCrafts.${PrepressLayoutCraftsEnum[data.prepressCrafts]}`);
  }

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getStatusI18Key(status: ProductionProductStatusEnum): string {
    return productionOrderProductService.getStatusI18Key(status);
  }

  /**
   * 根据工序状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getProcessStatusI18Key(status: ProcessStatusEnum): string {
    return productionOrderProductService.getProcessStatusI18Key(status);
  }

  /**
   * 根据状态获取class
   * @param status  状态值
   * @returns
   */
  public getStatusClass(status: ProductionProductStatusEnum): string {
    return productionOrderProductService.getStatusClass(status);
  }

  /**
   * 获取当前工序
   * @param rowData 行数据
   */
  public getCurrentProcess(rowData: ProductionOrderProductResource): string {
    return productionOrderProductService.getProcessI18Key(rowData.processType);
  }

  /**
   * 获取工序国际化key
   * @param type 工序
   * @returns
   */
  public getProcessTypeI18nKey(operation: OperationTypeEnum): string {
    return 'operationTypeEnum.' + OperationTypeEnum[operation];
  }

  /**
   * 获取工序操作国际化key
   * @param action 操作类型
   * @returns
   */
  public getProcessActionI18nKey(action: ProcessActionEnum): string {
    return 'processActionEnum.' + ProcessActionEnum[action];
  }

  /**
   * 获取产品类型国际化key
   * @param type 产品类型
   * @returns
   */
  public getProductTypeI18nKey(type: number): string {
    return 'orderProductType.' + OrderProductTypeEnum[type];
  }

  // /**
  //  * 获取计量单位国际化key
  //  * @param unit 计量单位
  //  * @returns
  //  */
  // public getChargeUnitI18nKey(priceUnit: ChargeUnitEnum): string {
  //   return 'chargeUnit.' + ChargeUnitEnum[priceUnit];
  // }

  /**
   * 打开订单产品详情
   * @param rowData 订单产品数据
   */
  public openProductDetailDialog(rowData: ProductionOrderProductResource): void {
    productionOrderProductService
      .getById(rowData.orderItemId)
      .then(res => {
        this.viewRow = res;
        this.viewRow.progressLogList.forEach(item => {
          item.createTime = dateFormat(item.createTime);
        });
        this.detailDialogVisible = true;
      })
      .catch(error => {
        messageError(error);
      });
  }

  /**
   * 打开报损记录页面
   * @param rowData 订单产品数据
   */
  public openBrokenLogDialog(rowData: ProductionOrderProductResource): void {
    this.brokenDetailDialogVisible = true;
    this.viewRow = rowData;
  }

  public handleBrokenLogDialogOpen(): void {
    this.loadBrokenData();
  }

  /**
   * 检索按钮
   */
  public handleQueryClick(): void {
    this.loadData();
  }

  /**
   * 分页切换回调
   */
  public pagingData(): void {
    this.loadData();
  }

  /**
   * 表格行选中事件
   */
  public handleSelectionChange(selectedData: Array<ProductionOrderProductResource>): void {
    this.selectedRows = selectedData;
  }

  /**
   * 重新加载数据
   */
  public reloadData(): void {
    this.paging.currentPage = 1;
    this.$table.clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  /**
   * 报损记录分页切换回调
   */
  public brokenLogTablePagingData(): void {
    this.loadBrokenData();
  }

  /**
   * 获取耗损类型国际化key
   * @param type 耗损类型
   */
  public getBrokenType(type: number): string {
    return 'brokenType.' + BrokenTypeEnum[type];
  }

  /**
   * 获取耗损工序国际化key
   * @param process 耗损工序
   */
  public getBrokenProcess(process: number): string {
    return 'orderProduct.' + ProcessEnum[process];
  }

  /**
   * 获取计量单位国际化key
   * @param unit 计量单位
   */
  public getPriceUnit(unit: number): string {
    return 'chargeUnit.' + ChargeUnitEnum[unit];
  }

  /**
   * 获取耗损原因国际化key
   * @param reason 耗损原因
   */
  public getBrokenReason(reason: number): string {
    return 'brokenReason.' + BrokenReasonEnum[reason];
  }

  /**
   * 打开新窗口预览
   * @param fileUrl 文件
   */
  public handleOpenWindowPreview(fileUrl: string): void {
    window.open(fileUrl, '_blank');
  }

  /**
   * 报损记录数据查询
   */
  private loadBrokenData(): void {
    const queryForm = {
      itemCode: this.viewRow?.itemCode
    };

    (this.$refs.brokenLogTableRef as OsTable).openLoading();
    productionOrderProductService
      .getProductConsumeList(queryForm as any, this.brokenLogTablePaging)
      .then(res => {
        this.brokenLogTableOption.data = res.data;
        this.brokenLogTableTotalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        (this.$refs.brokenLogTableRef as OsTable).closeLoading();
      });
  }

  /**
   * 加载数据
   */
  private loadData(): void {
    this.$table.openLoading();

    const query = cloneDeep(this.searchForm);
    query.status = query.status === 0 ? undefined : query.status;
    query.orderId = this.orderId;

    productionOrderProductService
      .getList(query as ProductionOrderProductResource, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;

        this.initImageFullUrl();
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.$table.closeLoading();
      });
  }

  private async initImageFullUrl(): Promise<void> {
    this.previewSrcList = [];
    // await minioService.init();
    this.tableOption.data.forEach(async item => {
      if (item.prepressConfirmFile) {
        const canPrepressConfirmFilePreview = this.canPrepressConfirmFilePreview(item.prepressConfirmFile);

        const url = item.prepressConfirmFile;

        if (canPrepressConfirmFilePreview) {
          this.previewSrcList.push(url);
        }
      }
    });
  }

  /**
   * 判断文件是否可预览
   * @param filePath 文件路径
   * @returns
   */
  private canPrepressConfirmFilePreview(filePath: string): boolean {
    const notAllowPreviewExtNameRegex = /^pdf/;
    const fileExtName = filePath.substring(filePath.lastIndexOf('.') + 1);

    if (notAllowPreviewExtNameRegex.test(fileExtName)) {
      return false;
    }

    return true;
  }
}
